// 代理管理的路由
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default [
  {
    path: "/agenManage", // 代理管理
    name: "agenManage",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
    redirect: "/agenManage/upertubeManage",
    meta: { title: "代理管理" },
    children: [
      {
        path: "/agenManage/upertubeManage", // 管理员代理
        name: "upertubeManage",
        meta: { title: "代理列表", isShow: true },
        component: () =>
          import(
            /* webpackChunkName: "upertubeManage" */ "@/views/agenManage/upertubeManage.vue"
          ),
        children: [
          {
            path: "/agenManage/upertubeManage/agenEdit/:id", // 编辑菜单
            name: "agenEdit",
            meta: { title: "新建/编辑菜单" },
            component: () =>
              import(
                /* webpackChunkName: "agenEdit" */ "@/views/agenManage/AgenEdit.vue"
              ),
            },
            {
                path: "/agenManage/upertubeManage/agenOrder/:id", // 编辑菜单
                name: "agenOrder",
                meta: { title: "代理订单列表" },
                component: () =>import( /* webpackChunkName: "agenOrder" */ "@/views/agenManage/agenOrder.vue" ),
            },
        ],
      },
    ],
  },
];
