// 系统管理的路由
import Vue from 'vue';
import Router from 'vue-router'
Vue.use(Router);

export default [
    {
        path: '/systemManage',   // 系统管理
        name: 'systemManage',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
        redirect: "/systemManage/menuManage",
        meta: { title: '系统管理' },
        children: [
            {
                path: '/systemManage/menuManage',  // 菜单管理
                name: 'menuManage',
                meta: { title: '菜单管理', isShow: true },
                component: () => import(/* webpackChunkName: "menuManage" */ '@/views/systemManage/MenuManage.vue'),
                children: [
                    {
                        path: '/systemManage/menuManage/editMenu/:id',  // 编辑菜单
                        name: 'editMenu',
                        meta: { title: '新建/编辑菜单' },
                        component: () => import(/* webpackChunkName: "editMenu" */ '@/views/systemManage/EditMenu.vue'),
                    },
                ]
            },
        ]
    },
]