import Vue from 'vue'
import VueRouter from 'vue-router'
import SystemManage from './systemManage/router' //系统管理
import RolePower from './rolePower/router' //角色权限
import AgenManage from "./agenManage/router"; //代理管理
import ConstMange from "./constMange/router"; //成本管理
import OrderManage from "./orderManage/router"; //订单管理
import PersonnelManage from "./personnelManage/router"; //收益管理
import ProfitManage from "./profitManage/router"; //收益管理

// 防止重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => { })
};

Vue.use(VueRouter);
// 所有路由集合
let allRouterArr = [
    ...SystemManage,
    ...RolePower,
    ...AgenManage,
    ...ConstMange,
    ...OrderManage,
    ...PersonnelManage,
    ...ProfitManage,
];
//  动态路由
let powerListArr = JSON.parse(localStorage.getItem("powerListArr"))
if (powerListArr) {
    let powerListObj = powerListArr.reduce((pre, current) => {
        pre[current.code] = "xxx";
        return pre;
    }, {});
    let childPowerListArr = [];
    powerListArr.map(item => {
        if (item.children.length > 0) {
            item.children.map(subitem => {
                childPowerListArr.push(subitem);
            })
        }
    })
    let childPowerListObj = childPowerListArr.reduce((pre, current) => {
        pre[current.code] = "xxx";
        return pre;
    }, {})
    let newAllRouterArr = [];   // 过滤后的路由数组
    allRouterArr.map(item => {
        let subNewAllRouterArr = [];
        if (powerListObj[item.name]) {
            newAllRouterArr.push(item);
            item.children.forEach(subItem => {
                if (childPowerListObj[subItem.name]) {
                    subNewAllRouterArr.push(subItem);
                }
            })
            if (item.children.length > 0) {
                item.children = subNewAllRouterArr;
            }
        }
    });
    allRouterArr = newAllRouterArr;
}


const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',  // 登录页
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/home',  // 首页
    name: 'home',
    redirect: "/home/home",
    meta: { title: '首页' },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    children: [
      {
        path: '/home/home',  // 控制台
        name: 'subHome',
        meta: { title: '控制台' },
        component: () => import(/* webpackChunkName: "subHome" */ '@/views/public/Home.vue')
      },
    ]
  },
  {
    path: '/home',
    name: 'home',
    redirect: "/home/home",
    meta: { title: '首页' },
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    children: [
      {
        path: '/home/noPower',  // 没有权限
        name: 'noPower',
        component: () => import(/* webpackChunkName: "noPower" */ '@/views/public/NoPower.vue')
      },
    ]
  },
  {
    path: '/nothingness',  // 404
    name: 'nothingness',
    component: () => import(/* webpackChunkName: "nothingness" */ '@/views/public/Nothingness.vue')
  },
  ...allRouterArr,  // Nothingness
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router