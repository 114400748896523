// 用户管理的路由
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default [
  {
    path: "/personnelManage", // 用户管理
    name: "personnelManage",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
    redirect: "/personnelManage/userList",
    meta: { title: "用户管理" },
    children: [
      {
        path: "/personnelManage/userList", // 管理员用户列表
        name: "userList",
        meta: { title: "管理员用户列表", isShow: true },
        component: () =>
          import(
            /* webpackChunkName: "upertubeManage" */ "@/views/personnelManage/userList.vue"
          ),
        children: [
          // {
          //   path: "/personnelManage/userList/agenEdit/:id", // 编辑订单
          //   name: "agenEdit",
          //   meta: { title: "新建/编辑菜单" },
          //   component: () => import(/* webpackChunkName: "agenEdit" */ "@/views/personnelManage/AgenEdit.vue"),
          // },
        ],
      },
    ],
  },
];
