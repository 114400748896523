// 收益管理的路由
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default [
  {
    path: "/profitManage", // 收益管理
    name: "profitManage",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
    redirect: "/profitManage/incomeDetails",
    meta: { title: "收益" },
    children: [
      {
        path: "/profitManage/incomeDetails", // 收益明细
        name: "incomeDetails",
        meta: { title: "收益明细", isShow: true },
        component: () =>
          import(
            /* webpackChunkName: "incomeDetails" */ "@/views/profitManage/incomeDetails.vue"
          ),
        children: [
          {
            path: "/profitManage/incomeDetails/applyWithdrawal", // 申请提现
            name: "applyWithdrawal",
            meta: { title: "申请提现" },
            component: () =>
              import(
                /* webpackChunkName: "applyWithdrawal" */ "@/views/profitManage/applyWithdrawal.vue"
              ),
          },
        ],
      },
    ],
  },
];
