<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <router-view/>
        </div>
    </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";

export default {
    data() {
        return {
            locale: zhCN,
        };
    },
};
</script>
<style lang="less">
#app {
    min-width: 1440px;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
}

.ant-btn-primary {
    color: #fff;
    background-color: #FF8200;
    border-color: #FF8200;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #FF8200;
}

.ant-radio-checked::after {
    border: 1px solid #FF8200;
}

.ant-radio-inner::after {
    background-color: #FF8200;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: #FF8200;
    border-color: #FF8200;
}

.ant-btn:hover, .ant-btn:focus {
    color: #FF8200;
    background-color: #fff;
    border-color: #FF8200;
}

a {
    color: #FF8200;
}

a:hover {
    color: #FF8200;
}

.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 14px 16px;
}

.ant-btn-dashed {
    color: #FF8200;
    background-color: #fff;
    border-color: #FF8200;
    font-weight: 600;
}

.ant-pagination-item-active a {
    color: #FF8200
}

.ant-pagination-item-active {
    border-color: #FF8200;
}

.ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: #FF8200;
}

.ant-pagination-item:focus a, .ant-pagination-item:hover a {
    color: #FF8200;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #FF8200;
    border-color: #FF8200;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #FF8200;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #FF8200;
}

.ant-checkbox-checked::after {
    border: 1px solid #FF8200;
}

.ant-switch-checked {
    background-color: #FF8200;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #FF8200;
    border-color: #FF8200;
}

.ant-tree-checkbox:hover .ant-tree-checkbox-inner {
    border-color: #FF8200;
}

.ant-tree-checkbox-checked::after {
    border: 1px solid #FF8200;
}

.ant-radio-input:focus + .ant-radio-inner {
    border-color: #FF8200;
}

.ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
}

.ant-radio:hover .ant-radio-inner {
    border-color: #FF8200;
}

.ant-tabs-nav .ant-tabs-tab-active {
    color: #FF8200;
}

.ant-tabs-ink-bar {
    background-color: #FF8200;
}

.ant-tabs-nav .ant-tabs-tab:hover {
    color: #FF8200;
}

.ant-input:hover {
    border-color: #FF8200;
}

.ant-input:focus {
    border-color: #FF8200;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none;
}

.ant-breadcrumb > span:last-child {
    color: #FF8200;
}

.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #FFF3E6;
}

.ant-select-selection:hover {
    border-color: #FF8200;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #FF8200;
}

.ant-calendar-picker:hover {
    border-color: #FF8200;
}

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #FF8200;
    box-shadow: none;
}

.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #FF8200;
}

.ant-calendar-today .ant-calendar-date {
    color: #FF8200;
    border-color: #FF8200;
}

.ant-calendar-date:hover {
    background: #FFF3E6;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date {
    background: #FF8200;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
    background: #FFF3E6;
}

.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
    background: #FF8200;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover, .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
    background: #FF8200;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background: #FFF3E6;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background: #FFF3E6;
}

.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    border-color: #FF8200;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none;
}

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #FFF3E6;
}

.ant-cascader-menu-item:hover {
    background: #FFF3E6;
}

.ant-cascader-picker:focus .ant-cascader-input {
    border-color: #FF8200;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: none;
}

.ant-menu-item-selected, .ant-menu-item-selected > a, .ant-menu-item-selected > a:hover {
    color: #FF8200;
}

.ant-input-number:hover {
    border-color: #FF8200;
}

.ant-input-number:focus {
    border-color: #FF8200;
    box-shadow: none;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #FF8200;
}

.ant-input-number-focused {
    box-shadow: none;
}

.ant-input-number-focused, .ant-input-number:hover {
    border-color: #FF8200;
}

.ant-table-row-expand-icon:focus, .ant-table-row-expand-icon:hover {
    color: #FF8200;
}

.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: #FF8200;
    border-color: #FF8200;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #FF8200;
}
</style>
