// 成本管理的路由
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default [
  {
    path: "/costManage", // 成本管理
    name: "costManage",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
    redirect: "/costManage/setupManage",
    meta: { title: "成本管理" },
    children: [
        {
            path: "/costManage/setupManage", // 成本设置
            name: "setupManage",
            meta: { title: "成本设置", isShow: true },
            component: () =>
            import(
                /* webpackChunkName: "setupManage" */ "@/views/costManage/setupManage.vue"
            ),
            children: [
            ],
        },
        {
            path: "/costManage/filterManage", // 过滤课程设置
            name: "filterManage",
            meta: { title: "过滤课程设置", isShow: true },
            component: () =>
              import(
                /* webpackChunkName: "setupManage" */ "@/views/costManage/filterManage.vue"
              ),
            children: [
            ],
        },
    ],
  },
];
