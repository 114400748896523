// 订单管理的路由
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export default [
  {
    path: "/orderManage", // 订单管理
    name: "orderManage",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
    redirect: "/orderManage/orderList",
    meta: { title: "订单管理" },
    children: [
      {
        path: "/orderManage/orderList", // 订单列表
        name: "orderList",
        meta: { title: "订单列表", isShow: true },
        component: () =>
          import(
            /* webpackChunkName: "upertubeManage" */ "@/views/orderManage/orderList.vue"
          ),
        children: [],
      },
      {
        path: "/orderManage/withdrawal", // 提现申请
        name: "withdrawal",
        meta: { title: "提现申请", isShow: true },
        component: () =>
          import(
            /* webpackChunkName: "withdrawal" */ "@/views/orderManage/withdrawal.vue"
          ),
        children: [
          {
            path: "/orderManage/withdrawal/detailed/:id", // 收益
            name: "detailed",
            meta: { title: "收益明细" },
            component: () => import(/* webpackChunkName: "agenEdit" */ "@/views/orderManage/detailed.vue"),
          },
        ],
      },
    ],
  },
];
